/* ----------------- Colores -------------------------------*/

.background-blue {
  background-color: #1c4d9d;
}
.background-yellow {
  background-color: #ff931f;
}
.background-yellow-secondary {
  background-color: #ff931e;
}
.background-black {
  background-color: #000000;
}
.background-greenDark {
  background-color: #009b67;
}
.background-sky-blue {
  background-color: #3fa9f5;
}
.background-fucsia {
  background-color: #ed1e79;
}
.background-red,
.background-incident {
  background-color: red;
}
.background-green-light {
  background-color: #00bb6f;
}
.color-red {
  color: red;
}
.color-green {
  color: #009b67;
}
.color-white-gray {
  color: #b9b9b9;
}
.color-gray {
  color: #313131;
}
.color-gray-light {
  color: #808080;
}

.ant-calendar-picker .ant-input-lg,
.ant-time-picker.ant-time-picker-large .ant-time-picker-input {
  font-size: 14px;
}

.height-antd-time {
  height: 40px;
}

body .ant-modal-wrap {
  z-index: 1200;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

/* .ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  background: none;
} */
