@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  padding: 0;
  background: #f6f6f6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Roboto";
}

#root {
  font-family: "Roboto";
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
  -webkit-animation: lds-spinner 1.2s linear infinite;
          animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    z-index: 1400;
  }

  15% {
    opacity: 1;
    z-index: 1400;
  }
  75% {
    opacity: 1;
    z-index: 1400;
  }

  100% {
    opacity: 0;
    z-index: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    z-index: 1400;
  }

  15% {
    opacity: 1;
    z-index: 1400;
  }
  75% {
    opacity: 1;
    z-index: 1400;
  }

  100% {
    opacity: 0;
    z-index: 0;
  }
}

.spinner-loading {
  opacity: 0;

  -webkit-animation: fadeIn ease-in 1;

          animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;

  /* animation-iteration-count: 1; */
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
body .ant-calendar-picker-container {
  z-index: 1250;
}

/* ----------------- Colores -------------------------------*/

.background-blue {
  background-color: #1c4d9d;
}
.background-yellow {
  background-color: #ff931f;
}
.background-yellow-secondary {
  background-color: #ff931e;
}
.background-black {
  background-color: #000000;
}
.background-greenDark {
  background-color: #009b67;
}
.background-sky-blue {
  background-color: #3fa9f5;
}
.background-fucsia {
  background-color: #ed1e79;
}
.background-red,
.background-incident {
  background-color: red;
}
.background-green-light {
  background-color: #00bb6f;
}
.color-red {
  color: red;
}
.color-green {
  color: #009b67;
}
.color-white-gray {
  color: #b9b9b9;
}
.color-gray {
  color: #313131;
}
.color-gray-light {
  color: #808080;
}

.ant-calendar-picker .ant-input-lg,
.ant-time-picker.ant-time-picker-large .ant-time-picker-input {
  font-size: 14px;
}

.height-antd-time {
  height: 40px;
}

body .ant-modal-wrap {
  z-index: 1200;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

/* .ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  background: none;
} */

body {
  overflow-x: hidden;
}
body.modal-open {
  overflow-x: hidden;
}

button {
  cursor: pointer;
}
@media only screen and (min-width: 320px) {
  /* ----------------- Login -------------- */

  /* ----------------- Header --------------- */
  .App-map {
    height: 100vh;
    width: 100%;
    top: 0;
    position: fixed;
    /* overflow: hidden; */
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000000;
  }
  .slick-slide {
    padding: 15px 15px 15px 0;
  }

  .slider .slider-item {
    cursor: pointer;
  }
  .slider-item {
    transition: all 0.2s linear;
    margin-right: 0;
    margin-left: 0;
    border-radius: 5px;
  }

  .slider-item {
    position: relative;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.9),
      0 0px 25px rgba(0, 0, 0, 0.8) inset;
    z-index: 2;
  }
  .slider-item:before,
  .slider-item:after {
    content: "";
    position: absolute;
    z-index: -1;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
    top: 0;
    bottom: 0;
    left: 10px;
    right: 10px;
    border-radius: 100px / 10px;
  }
  .slider-item:after {
    right: 10px;
    left: auto;
    -webkit-transform: skew(8deg) rotate(3deg);
            transform: skew(8deg) rotate(3deg);
  }

  .slider-item .card-body,
  .hide .card-body {
    display: flex;
    color: #ffffff;
    font-weight: 0;
    padding: 15px;
    background: #1c4d9d;
    align-items: center;
    border-radius: 0 0 5px 5px;
  }
  .hide .card-body {
    background-color: #ededed;
    color: #000;
  }
  .card-body img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
  }

  .slick-arrow {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background-color: rgba(0, 0, 0, 0.65);
    color: transparent;
    border: none;
    font-size: 40px;
    cursor: pointer;
    outline: none;
    transition: all 0.2s linear;
    /* text-indent: -999px */
  }
  .slick-prev {
    left: 0.5%;
    z-index: 2;
  }
  .slick-next {
    right: 0.5%;
    z-index: 2;
  }
  .slick-next:focus,
  .slick-next:hover,
  .slick-prev:focus,
  .slick-prev:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
  .slick-prev:before,
  .slick-next:before {
    content: none;
  }
  .slick-next .fas,
  .slick-prev .fas {
    font-size: 20px;
    opacity: 1;
    color: #fff;
  }

  .slick-dots {
    bottom: -30px;
  }

  .slick-dots li button {
    background-color: #d6d6d6;
  }
  .slick-dots .slick-active button {
    background-color: #949494;
  }

  /* ----------  OPERATION  --------------------*/

  .title-table-vehicle {
    color: #000000;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
  .title-table-vehicle span {
    font-weight: bold;
  }
  .content-map {
    display: none;
  }

  .none-btn {
    background-color: #dbd9d9;
  }

  .modalTable {
    width: 450px;
  }
  /* .content-table {
    padding: 29px 10px 20px;
  } */
  table.table th {
    font-weight: bold;
  }
  .status {
    color: #ffffff;
    padding: 2px 8px;
  }
  table.table td,
  table.table th {
    padding-top: 17px;
    padding-bottom: 17px;
    font-size: 13px;
  }

  .table td,
  .table th {
    padding: 0;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  .btn-table {
    border: 1px solid #1c4d9d;
    background-color: #ffffff;
    padding: 3px 8px;
    color: #1c4d9d;
    font-family: "Roboto";
    font-size: 13px;
    line-height: 15px;
  }
  .table td.cell-button {
    padding-top: 14px;
    padding-bottom: 9px;
  }
  /* .openTable {
    max-width: 40%;
    flex: 0 0 40%;
  }
  .openMap {
    max-width: 60%;
    flex: 0 0 60%;
  } */

  .modal-body table {
    font-size: 13px;
  }
  .modal-body table.table td,
  table.table th {
    padding-top: 17px;
    padding-bottom: 17px;
    font-size: 13px;
  }
  /* ----------------------- Modal ----------------  */
  /* .modal-body {
    padding-top: 11px;
    padding-bottom: 22px;
  } */
  .modal-title {
    color: #000000;
    font-family: "Roboto";
    width: 260px;
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
  }
  .modal-title span {
    font-weight: bold;
  }
  .closeSpan.big {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  .statusModal {
    box-shadow: 0 0 8px 0 hsla(0, 0%, 81.6%, 0.5);
    background-color: #ed1e79;
    padding: 4px 14px;
    color: #fff;
  }
  /* .modal-header .btn-close {
    position: absolute;
    z-index: 5;
    top: 0;
    right: 15px;
    background-color: transparent;
    border: 0;
    padding: 6px 0;
  } */
  /* .closeSpan {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 30px;
    overflow: hidden;
    opacity: 1;
  } */
  .closeSpan::before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .closeSpan::after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  /* .closeSpan::before,
  .closeSpan::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
  } */
  /* .modal-header .btn-close .closeSpan::before,
  .modal-header .btn-close .closeSpan::after {
    background-color: #1c4d9d;
  } */
}

.align-self-flex-end {
  align-self: flex-end;
}
.align-self-center {
  align-self: center;
}
/* ----------------- Login ----------------- */

/* ----------------- Finish - Login ----------------- */

.ml-6 {
  margin-left: 4rem;
}
.ml-7 {
  margin-left: 5rem;
}
.ml-8 {
  margin-left: 6rem;
}
.ml-9 {
  margin-left: 7rem;
}

/* ----------------- HEADER - DASH */

header .dropdown-item i {
  margin-right: 10px;
}
/* ----------------- Finish - HEADER - DASH */

/* ------------- Content - dash */

.content {
  padding-bottom: 15px;
}
.border-top {
  border-top: 1px solid #dee2e6;
}
.dash .table {
  width: 90%;
  margin-bottom: 1rem;
  margin: 2rem auto 1rem;
}
.table-responsive.dash {
  background-color: #fff;
}
tbody {
  border-bottom: 1px solid rgba(56, 55, 55, 0.2);
}
.dash table.table thead th {
  border-top: none;
  font-size: 17px;
  border: 1px solid #1c4d9d;
  background-color: #1c4d9d;
}
.dash table.table th {
  color: #fff;
}
.dash table.table td {
  color: #000000;
}
.dash table.table td,
.dash table.table th {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  border-top: 1px solid rgba(56, 55, 55, 0.2);
}
.dash .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(56, 55, 55, 0.2);
}
.dash .table i {
  margin-right: 10px;
}

.ant-select-selection__rendered {
  width: 150px !important;
  margin-right: 40px;
}

/* -------------- Footer --------------------- */
footer {
  background-color: #009b67;
}
.page-footer {
  box-shadow: 18px 8px 13px 12px rgba(0, 0, 0, 0.16),
    0 20px 20px 0 rgba(0, 0, 0, 0.12);
}
.page-footer img {
  width: 120px;
  height: 40px;
}
.page-footer p {
  font-size: 10px;
  align-self: center;
  margin-bottom: 0;
  line-height: 3;
  margin-right: 5px;
}
.footer-text {
  font-size: 18px;
  font-family: "Roboto";
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 992px) {
  .page-footer p {
    font-size: 14px;
  }
  .App-map {
    height: 100%;
    width: 100%;

    position: relative;
    overflow: hidden;
  }
  /* ----------  OPERATION  --------------------*/

  .salidas-content h4 {
    color: #000000;
    font-family: "Roboto";
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 28px;
  }

  /* ---------------- Slide ---------------------- */

  .slick-slide {
    padding: 15px 15px 15px 0;
  }

  .slider .slider-item {
    cursor: pointer;
  }
  .slider-item {
    transition: all 0.2s linear;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 5px;
  }

  .slider-item {
    position: relative;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.9),
      0 0px 25px rgba(0, 0, 0, 0.8) inset;
    z-index: 2;
  }
  .slider-item:before,
  .slider-item:after {
    content: "";
    position: absolute;
    z-index: -1;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
    top: 0;
    bottom: 0;
    left: 10px;
    right: 10px;
    border-radius: 100px / 10px;
  }
  .slider-item:after {
    right: 10px;
    left: auto;
    -webkit-transform: skew(8deg) rotate(3deg);
            transform: skew(8deg) rotate(3deg);
  }

  .hide .card-body {
    display: flex;
    color: #ffffff;
    font-weight: 0;
    padding: 15px;
    background: #1c4d9d;
    align-items: center;
    border-radius: 0 0 5px 5px;
  }
  .hide .card-body {
    background-color: #ededed;
    color: #000;
  }
  .card-body img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
  }

  .slick-arrow {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    background-color: rgba(0, 0, 0, 0.65);
    color: transparent;
    border: none;
    font-size: 40px;
    cursor: pointer;
    outline: none;
    transition: all 0.2s linear;
    /* text-indent: -999px */
  }
  .slick-prev {
    left: 0.5%;
    z-index: 2;
  }
  .slick-next {
    right: 0.5%;
    z-index: 2;
  }
  .slick-next:focus,
  .slick-next:hover,
  .slick-prev:focus,
  .slick-prev:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
  .slick-prev:before,
  .slick-next:before {
    content: none;
  }
  .slick-next .fas,
  .slick-prev .fas {
    font-size: 40px;
    opacity: 1;
    color: #fff;
  }

  .slick-dots {
    bottom: -30px;
  }

  .slick-dots li button {
    background-color: #d6d6d6;
  }
  .slick-dots .slick-active button {
    background-color: #949494;
  }

  /* ---------------------------- table ------------------- */
  .table {
    width: 100%;
  }
  .title-table-vehicle {
    color: #000000;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }
  .title-table-vehicle span {
    font-weight: bold;
  }
  .showCell {
    display: table-cell;
  }
  .hideCell {
    display: none;
  }

  .content-table,
  .content-table-salidas {
    padding: 29px 20px 50px;
    /* transition: all 1s ease; */
  }
  .content-map {
    display: flex;
    max-width: 5%;
    flex: 0 0 5%;
    color: white;
    /* transition: all 1s ease; */
  }
  .width-100 {
    width: 100%;
  }
  .width-0 {
    width: 0;
  }
  .transition-map {
    /* transition: transform 2s; */
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);

    /* width: 0; */
  }
  .normal-map {
    /* transform: translate(20px, 20px); */
    /* position: absolute; */
    position: relative;
    float: left;
    /* transition: transform 2s; */

    -webkit-transform: translate(700px, 0px);

            transform: translate(700px, 0px);
  }
  .status {
    color: #ffffff;
    padding: 2px 8px;
  }
  table.table td,
  table.table th {
    padding-top: 17px;
    padding-bottom: 17px;
  }

  .table td,
  .table th {
    padding: 0;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  .btn-table {
    border: 1px solid #1c4d9d;
    background-color: #ffffff;
    padding: 3px 8px;
    color: #1c4d9d;
    font-family: "Roboto";
    font-size: 13px;
    line-height: 15px;
  }
  .table td.cell-button {
    padding-top: 14px;
    padding-bottom: 9px;
  }
  .openTable {
    max-width: 40%;
    flex: 0 0 40%;
  }
  .openMap {
    max-width: 60%;
    flex: 0 0 60%;
    left: 0;
    padding-left: 0;
    /* padding-top: 1em; */
    overflow: hidden;
    list-style: none;
    position: relative;
  }
  .tableWidth {
    width: 100%;
  }
  .heightTable {
    height: 766px;
  }
  .btn-map {
    height: 100%;
    display: flex;
    position: absolute;
    right: 0px;
    cursor: pointer;
    z-index: 2;
    background-color: #000000;
  }
  .btn-map p {
    -webkit-writing-mode: vertical-lr;
            writing-mode: vertical-lr;
    margin: auto;
    display: flex;
    width: 80px;
    height: 180px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
  }

  .btn-map img {
    margin-top: 10px;
  }
  /* ----------------------- Modal ----------------  */
  .modal-header {
    padding-right: 18px;
    padding-left: 36px;
  }
  /* .modal-body {
    padding-top: 11px;
    padding-bottom: 22px;
  } */
  .modal-title {
    color: #000000;
    font-family: "Roboto";
    text-transform: uppercase;
    width: 550px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }
  .modal-title span {
    font-weight: bold;
  }
  /* ------------- Map -------------------- */
  .btn-close-map {
    position: absolute;
    z-index: 5;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    border: 0;
    padding: 12px 20px;
  }
  /* .closeSpan {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 40px;
    overflow: hidden;
    opacity: 1;
  } */
  /* .closeSpan::before,
  .closeSpan::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
  } */
  .closeSpan::before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .closeSpan::after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  .closeSpan.big {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  table.table td,
  table.table th {
    color: #000000;
    font-family: "Roboto";
    font-size: 15px;
    line-height: 18px;
  }
  .modal-header {
    padding-top: 34px;
    padding-bottom: 11px;
    border-bottom: none;
    border-radius: 0;
    position: relative;
    border-bottom: 1px solid #5d5e5e;
  }
  /* .modal-header .btn-close {
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    background-color: #fff;
    border: 0;
    padding: 6px 20px;
  } */
  /* .modal-header .btn-close .closeSpan::before,
  .modal-header .btn-close .closeSpan::after {
    background-color: #1c4d9d;
  } */
  table.table th {
    font-weight: bold;
  }
  .statusModal {
    box-shadow: 0 0 8px 0 rgba(208, 208, 208, 0.5);
    background-color: #ed1e79;
    padding: 4px 14px;
    color: #fff;
  }

  .none-btn {
    background-color: #dbd9d9;
  }

  .text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .main-slide {
    position: relative;
  }

  /* ---------------- SALIDAS --------------------- */
  .content-table-salidas {
    background: #fff;
  }
  .detail-table label {
    color: #656565;
    font-family: Roboto;
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
  }
  .detail-table p {
    color: #656565;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
  }

  .btn-style button {
    background-color: #ffffff;
    border: 1px solid #424344;
    border-radius: 10px;
    padding: 10px 20px;
    color: #080808;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
  }
  .btn-table-salidas {
    background: #fff;
    border: none;
  }
  .title-section {
    color: #050505;
    font-family: "Roboto";
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  }
  .detail-modal {
    color: #0a0a0a;
    font-family: "Roboto";
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
  }
  .salidas-modal h5 {
    color: #050505;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }
  .btn-ingresos {
    background-color: #ffffff;
    border: 1px solid #1c4d9d;
    padding: 7px 15px;
    color: #1c4d9d;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
  }
  .modal-ingeso p,
  .modal-ingeso label {
    color: #050505;
    font-family: "Roboto";
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    /* text-align: left; */
  }
  .circle-ingreso {
    border-radius: 100%;
    width: 20px;
    height: 20px;
  }
  .text-residuo {
    width: 25%;
  }
  .number-residuo {
    width: 15%;
    text-align: right;
  }

  .circle-stock {
    border-radius: 100%;
    width: 30px;
    height: 30px;
  }
  .header-card-stock {
    color: "#03039";
    font-family: "Roboto";
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
  }
  .header-card-stock p {
    width: 100%;
    text-align: center;
  }
  .content-stock {
    padding: 100px 0;
    text-align: center;
    color: #808080;
    font-family: "Roboto";
    font-size: 26px;
    font-weight: 800;
  }

  .content-result-stock {
    padding: 40px;

    color: #808080;
    font-family: "Roboto";
    font-size: 26px;
    font-weight: 800;
    line-height: 33px;
    text-align: center;
  }

  .info-window h3 {
    color: #313030;
    font-family: "Roboto";
    font-size: 15px;
    font-weight: 500;
    text-align: center;
  }
  .ant-btn {
    height: unset;
  }
  .btn-modal-salida,
  .ant-modal-confirm .ant-btn {
    background-color: #eef2ef;
    border: none;
    padding: 10px 20px;
    color: #070707;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    height: unset;
    border-radius: unset;
  }
  .ant-confirm .ant-confirm-title,
  .ant-confirm .ant-confirm-content {
    color: #080808;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }
  .ant-confirm .ant-confirm-content {
    font-weight: 400;
  }
  .title-document {
    color: #050505;
    font-family: "Roboto";
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .link-document {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }
  .ant-modal-title {
    color: #050505;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }

  .ant-modal-wrap .ant-modal {
    width: 600px;
  }
  .errorInput {
    border: 1px solid red;
    border-radius: 4px;
  }
  .error-message {
    color: red;
    margin-bottom: 1px;
  }
  .modal-footer {
    border: 0;
  }
  .modal-confirm h3 {
    color: #080808;
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }
  .modal-confirm p {
    color: #030303;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    width: 371.5px;
    text-align: left;
  }
  .message-none {
    color: #030303;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
  }
}

@media only screen and (min-width: 1400px) {
  form {
    width: 100%;
    align-self: center;
  }

  table.table td,
  table.table th {
    font-size: 12px;
  }
}


.tabla-loader {
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabla-loader .lds-spinner div::after {
  background-color: #000;
}
